exports.linkResolver = (doc) => {
    console.log('doc: ', doc);
    if (doc.type === 'trec_page') {
        return `/trec/${doc.uid}`;
    }
    if (doc.type === 'page') {
        return `/${doc.uid}`;
    }
    if (doc.type === 'discover_page') {
        return `/discover/${doc.uid}`;
    }
    if (doc.type === 'static_page' && doc.uid === 'donate') {
        return `/teton-geo-center/${doc.uid}`;
    }
    if (doc.type === 'static_page' && doc.uid === 'volunteer') {
        return `/teton-geo-center/${doc.uid}`;
    }
    if (doc.type === 'static_page' && doc.uid === 'join') {
        return `/chamber-of-commerce/${doc.uid}`;
    }
    if (doc.type === 'static_page' && doc.uid === 'maps-travel-info') {
        return `/discover/${doc.uid}`;
    }
    if (doc.type === 'static_page') {
        return `/${doc.uid}`;
    }
    if (doc.type === 'news_and_events') {
        return `/news/${doc.uid}`;
    }
    if (doc.type === 'chamber_page') {
        return `/chamber-of-commerce/${doc.uid}`;
    }
    if (doc.type === 'geo_page') {
        return `/teton-geo-center/${doc.uid}`;
    }
    return '/';
}