import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { linkResolver } from './src/utils/linkResolver'
import { BsFileEarmarkPdf } from 'react-icons/bs'
import { ExternalLinkIcon } from '@chakra-ui/icons';

// PrismicRichText serializer
const components = {
  // handling this in the RichText component:
  
  // paragraph: ({ node, children }) => {
  //   console.log(node)
  //   return (
  //     <>
  //     {node.spans ? (
  //      <>
  //         {node.spans[0]?.data?.label === 'align_image_left' 
  //         ? (<div className='right-align'>
  //         {children}
  //           </div>) 
  //         : node.spans[0]?.data?.label === 'align_image_right' ? (<div className='left-align'>
  //         {children}
  //       </div>) : (<p>
  //       {children}
  //     </p>) }
  //      </>
  //     ): 
  //     (<p>
  //       {children}
  //     </p>)}
  //     </>
  //   )
  // },
  // image: ({ node, children }) => {
  //   const src = node.url
  //   const alt = node.alt
  //   return (
  //     <div>
  //       <img src={src} alt={alt} className="richTextImage" />
  //     </div>
  //   )
  // },
  hyperlink: ({ node, children }) => {
    return (
      <>
        { 
        node.data?.link_type === 'Document'
        ? (<Link to={linkResolver(node.data)} key={node.data.id}>{children}</Link>) 
        : 
        node.data?.link_type === 'Media' ?
        (<a href={node.data?.url} target="_blank" rel="noopener noreferrer" className='orange'>
          {children}
            {node.data?.url.includes('.pdf' || '.PDF') && (<span>{' '}<BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>)}
        </a>)
        : node.data?.link_type === 'Web' ?
        (<a href={node.data?.url} target="_blank" rel="noopener noreferrer">
          {children}
          {node.data?.url.includes('.pdf' || '.PDF') 
            ? (<span>{' '}<BsFileEarmarkPdf style={{display: 'inline-block', verticalAlign: 'middle'}}/></span>) 
            : (<span>{' '}<ExternalLinkIcon style={{display: 'inline-block', verticalAlign: 'middle', marginBottom: '0.25rem'}} /></span>)}
     </a>) : null }
      </>
    )
  }
}

export const wrapRootElement = ({ element }) => {
  return (  
    <PrismicProvider
      linkResolver={linkResolver}
      richTextComponents={components}
      internalLinkComponent={({ href, ...props }) => (
        <Link to={href} {...props} />
      )}
    >
      {element}
    </PrismicProvider>
)
}